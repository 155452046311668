#cc-main {
  color: var(--cc-primary-color);
  font-family: var(--cc-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  z-index: var(--cc-z-index);
  background: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15;
  position: fixed;
}

#cc-main :after, #cc-main :before, #cc-main a, #cc-main button, #cc-main div, #cc-main h2, #cc-main input, #cc-main p, #cc-main span {
  all: unset;
  box-sizing: border-box;
}

#cc-main .pm__badge, #cc-main button {
  all: initial;
  box-sizing: border-box;
  color: unset;
  visibility: unset;
}

#cc-main .pm__badge, #cc-main a, #cc-main button, #cc-main input {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: revert;
  outline-offset: 2px;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  overflow: hidden;
}

#cc-main table, #cc-main tbody, #cc-main td, #cc-main th, #cc-main thead, #cc-main tr {
  all: revert;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:root {
  --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --cc-modal-border-radius: .5rem;
  --cc-btn-border-radius: .4rem;
  --cc-modal-transition-duration: .25s;
  --cc-link-color: var(--cc-btn-primary-bg);
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147480000;
  --cc-bg: #fff;
  --cc-primary-color: #2c2f31;
  --cc-secondary-color: #5e6266;
  --cc-btn-primary-bg: #30363c;
  --cc-btn-primary-color: #fff;
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: #000;
  --cc-btn-primary-hover-color: #fff;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #d4dae0;
  --cc-btn-secondary-hover-color: #000;
  --cc-btn-secondary-hover-border-color: #d4dae0;
  --cc-separator-border-color: #f0f4f7;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #667481;
  --cc-toggle-on-knob-bg: #fff;
  --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);
  --cc-toggle-enabled-icon-color: var(--cc-bg);
  --cc-toggle-disabled-icon-color: var(--cc-bg);
  --cc-toggle-readonly-bg: #d5dee2;
  --cc-toggle-readonly-knob-bg: #fff;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
  --cc-section-category-border: var(--cc-cookie-category-block-bg);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-border: #f0f4f7;
  --cc-cookie-category-block-hover-bg: #e9eff4;
  --cc-cookie-category-block-hover-border: #e9eff4;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
  --cc-overlay-bg: #000000a6;
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: var(--cc-btn-secondary-bg);
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #e4eaed;
  --cc-pm-toggle-border-radius: 4em;
}

#cc-main.cc--rtl {
  direction: rtl;
}

#cc-main .cm__title, #cc-main a, #cc-main b, #cc-main em, #cc-main strong {
  font-weight: 600;
}

#cc-main button > span {
  pointer-events: none;
}

#cc-main .cc__link, #cc-main a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  font-weight: 600;
  transition: background-size .25s, color .25s;
  position: relative;
}

#cc-main .cc__link:hover, #cc-main a:hover {
  color: var(--cc-primary-color);
  background-size: 100% 1px;
}

#cc-main .cc__link {
  color: var(--cc-link-color);
}

#cc-main .cm__desc, #cc-main .pm__body {
  overscroll-behavior: auto contain;
  scrollbar-width: thin;
}

@media screen and (min-width: 640px) {
  #cc-main ::-webkit-scrollbar {
    all: revert;
  }

  #cc-main ::-webkit-scrollbar-thumb {
    all: revert;
  }

  #cc-main ::-webkit-scrollbar-track {
    all: revert;
  }

  #cc-main ::-webkit-scrollbar-thumb {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cc-toggle-readonly-bg);
    border: .25rem solid var(--cc-bg);
    border-radius: 1rem;
  }

  #cc-main ::-webkit-scrollbar-thumb:hover {
    background: var(--cc-toggle-off-bg);
  }

  #cc-main ::-webkit-scrollbar {
    background: none;
    width: 12px;
  }
}

html.disable--interaction.show--consent, html.disable--interaction.show--consent body {
  height: auto !important;
  overflow: hidden !important;
}

@media (prefers-reduced-motion) {
  #cc-main {
    --cc-modal-transition-duration: 0s;
  }
}

.cc--darkmode {
  --cc-bg: #161a1c;
  --cc-primary-color: #ebf3f6;
  --cc-secondary-color: #aebbc5;
  --cc-btn-primary-bg: #c2d0e0;
  --cc-btn-primary-color: var(--cc-bg);
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: #98a7b6;
  --cc-btn-primary-hover-color: #000;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: #242c31;
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #353d43;
  --cc-btn-secondary-hover-color: #fff;
  --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);
  --cc-separator-border-color: #222a30;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #525f6b;
  --cc-toggle-on-knob-bg: var(--cc-btn-primary-color);
  --cc-toggle-off-knob-bg: var(--cc-btn-primary-color);
  --cc-toggle-enabled-icon-color: var(--cc-btn-primary-color);
  --cc-toggle-disabled-icon-color: var(--cc-btn-primary-color);
  --cc-toggle-readonly-bg: #343e45;
  --cc-toggle-readonly-knob-bg: #5f6b72;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
  --cc-section-category-border: #1e2428;
  --cc-cookie-category-block-bg: #1e2428;
  --cc-cookie-category-block-border: var(--cc-section-category-border);
  --cc-cookie-category-block-hover-bg: #242c31;
  --cc-cookie-category-block-hover-border: #232a2f;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: var(--cc-toggle-readonly-bg);
  --cc-overlay-bg: #000000a6;
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: #0c0e0f;
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #060809;
}

.cc--darkmode #cc-main {
  color-scheme: dark;
}

#cc-main .cm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  flex-direction: column;
  max-width: 24rem;
  display: flex;
  position: fixed;
  overflow: hidden;
  transform: translateY(1.6em);
  box-shadow: 0 .625em 1.875em #0000024d;
}

#cc-main .cm--top {
  top: var(--cc-modal-margin);
}

#cc-main .cm--middle {
  top: 50%;
  transform: translateY(calc(1.6em - 50%));
}

#cc-main .cm--bottom {
  bottom: var(--cc-modal-margin);
}

#cc-main .cm--center {
  left: var(--cc-modal-margin);
  right: var(--cc-modal-margin);
  width: unset;
  margin: 0 auto;
}

#cc-main .cm--left {
  left: var(--cc-modal-margin);
  margin-right: var(--cc-modal-margin);
}

#cc-main .cm--right {
  margin-left: var(--cc-modal-margin);
  right: var(--cc-modal-margin);
}

#cc-main .cm__body {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

#cc-main .cm__btns, #cc-main .cm__links {
  width: unset;
  padding: 1rem 1.3rem;
}

#cc-main .cm__texts {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 1rem 0 0;
  display: flex;
}

#cc-main .cm__desc, #cc-main .cm__title {
  padding: 0 1.3rem;
}

#cc-main .cm__title {
  font-size: 1.05em;
}

#cc-main .cm__title + .cm__desc {
  margin-top: 1.1em;
}

#cc-main .cm__desc {
  color: var(--cc-secondary-color);
  max-height: 40vh;
  padding-bottom: 1em;
  font-size: .9em;
  line-height: 1.5;
  overflow-x: visible;
  overflow-y: auto;
}

#cc-main .cm__btns {
  border-top: 1px solid var(--cc-separator-border-color);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#cc-main .cm__btn-group {
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

#cc-main .cm__btn + .cm__btn, #cc-main .cm__btn-group + .cm__btn-group {
  margin-top: .375rem;
}

#cc-main .cm--flip .cm__btn + .cm__btn, #cc-main .cm--flip .cm__btn-group + .cm__btn-group {
  margin-top: 0;
  margin-bottom: .375rem;
}

#cc-main .cm--inline .cm__btn + .cm__btn {
  margin-top: 0;
  margin-left: .375rem;
}

#cc-main .cm--inline.cm--flip .cm__btn + .cm__btn {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main .cm--inline.cm--flip .cm__btn-group + .cm__btn-group {
  margin-bottom: .375rem;
  margin-right: 0;
}

#cc-main .cm--wide .cm__btn + .cm__btn, #cc-main .cm--wide .cm__btn-group + .cm__btn-group {
  margin-top: 0;
  margin-left: .375rem;
}

#cc-main .cm--wide.cm--flip .cm__btn + .cm__btn, #cc-main .cm--wide.cm--flip .cm__btn-group + .cm__btn-group {
  margin-bottom: 0;
  margin-right: .375rem;
}

#cc-main .cm--bar:not(.cm--inline) .cm__btn-group--uneven, #cc-main .cm--wide .cm__btn-group--uneven {
  flex: 1;
  justify-content: space-between;
  display: flex;
}

#cc-main .cm--bar:not(.cm--inline).cm--flip .cm__btn-group--uneven, #cc-main .cm--wide.cm--flip .cm__btn-group--uneven {
  flex-direction: row-reverse;
}

#cc-main .cm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  text-align: center;
  min-height: 42px;
  padding: .5em 1em;
  font-size: .82em;
  font-weight: 600;
}

#cc-main .cm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color);
}

#cc-main .cm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color);
}

#cc-main .cm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color);
}

#cc-main .cm__btn--close {
  border-radius: 0;
  border-bottom-left-radius: var(--cc-btn-border-radius);
  border-top: none;
  border-right: none;
  width: 42px;
  height: 42px;
  font-size: 1em;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  min-width: auto !important;
  padding: 0 !important;
}

#cc-main .cm__btn--close svg {
  stroke: var(--cc-btn-primary-color);
  transition: stroke .15s;
  transform: scale(.5);
}

#cc-main .cm__btn--close:hover svg {
  stroke: var(--cc-btn-primary-hover-color);
}

#cc-main .cm__btn--close.cm__btn--secondary svg {
  stroke: var(--cc-btn-secondary-color);
}

#cc-main .cm__btn--close.cm__btn--secondary:hover svg {
  stroke: var(--cc-btn-secondary-hover-color);
}

#cc-main .cm__btn--close + .cm__texts .cm__title {
  padding-right: 3rem;
}

#cc-main .cm--inline .cm__btn-group {
  grid-auto-flow: column;
}

#cc-main .cm__footer {
  background: var(--cc-footer-bg);
  border-top: 1px solid var(--cc-footer-border-color);
  color: var(--cc-footer-color);
  padding: .4em 0 .5em;
}

#cc-main .cm__links {
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

#cc-main .cm__link-group {
  flex-direction: row;
  width: 100%;
  font-size: .8em;
  display: flex;
}

#cc-main .cm__link-group > * + * {
  margin-left: 1.3rem;
}

#cc-main .cm--flip .cm__btn:last-child {
  grid-row: 1;
}

#cc-main .cm--inline.cm--flip .cm__btn:last-child {
  grid-column: 1;
}

#cc-main .cm--box .cm__btn--close {
  display: block;
}

#cc-main .cm--box.cm--flip .cm__btns {
  flex-direction: column-reverse;
}

#cc-main .cm--box.cm--wide {
  max-width: 36em;
}

#cc-main .cm--box.cm--wide .cm__btns {
  flex-direction: row;
  justify-content: space-between;
}

#cc-main .cm--box.cm--wide .cm__btn-group {
  grid-auto-flow: column;
}

#cc-main .cm--box.cm--wide .cm__btn {
  min-width: 120px;
  padding-left: 1.8em;
  padding-right: 1.8em;
}

#cc-main .cm--box.cm--wide.cm--flip .cm__btns {
  flex-direction: row-reverse;
}

#cc-main .cm--box.cm--wide.cm--flip .cm__btn:last-child {
  grid-column: 1;
}

#cc-main .cm--cloud {
  width: unset;
  max-width: 54em;
}

#cc-main .cm--cloud .cm__body {
  flex-direction: row;
}

#cc-main .cm--cloud .cm__texts {
  flex: 1;
}

#cc-main .cm--cloud .cm__desc {
  max-height: 9.4em;
}

#cc-main .cm--cloud .cm__btns {
  border-left: 1px solid var(--cc-separator-border-color);
  border-top: none;
  max-width: 23em;
}

#cc-main .cm--cloud .cm__btn-group {
  flex-direction: column;
}

#cc-main .cm--cloud .cm__btn {
  min-width: 19em;
}

#cc-main .cm--cloud.cm--flip .cm__btn-group, #cc-main .cm--cloud.cm--flip .cm__btns {
  flex-direction: column-reverse;
}

#cc-main .cm--cloud.cm--inline .cm__btn-group {
  flex-direction: row;
}

#cc-main .cm--cloud.cm--inline .cm__btn {
  min-width: 10em;
}

#cc-main .cm--cloud.cm--inline.cm--flip .cm__btn-group {
  flex-direction: row-reverse;
}

#cc-main .cm--bar {
  max-width: unset;
  opacity: 1;
  --cc-modal-transition-duration: .35s;
  border-radius: 0;
  width: 100vw;
  margin: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
}

#cc-main .cm--bar.cm--top {
  top: 0;
  transform: translateY(-100%);
}

#cc-main .cm--bar.cm--bottom {
  bottom: 0;
  transform: translateY(100%);
}

#cc-main .cm--bar .cm__body, #cc-main .cm--bar .cm__links {
  width: 100%;
  max-width: 55em;
  margin: 0 auto;
}

#cc-main .cm--bar .cm__body {
  padding: .5em 0 .9em;
}

#cc-main .cm--bar .cm__btns {
  border-top: none;
  flex-direction: row;
  justify-content: space-between;
}

#cc-main .cm--bar .cm__btn-group {
  grid-auto-flow: column;
}

#cc-main .cm--bar:not(.cm--inline) .cm__btn + .cm__btn, #cc-main .cm--bar:not(.cm--inline) .cm__btn-group + .cm__btn-group {
  margin-top: 0;
  margin-left: .375rem;
}

#cc-main .cm--bar .cm__btn {
  min-width: 120px;
  padding-left: 2em;
  padding-right: 2em;
}

#cc-main .cm--bar.cm--flip:not(.cm--inline) .cm__btn + .cm__btn, #cc-main .cm--bar.cm--flip:not(.cm--inline) .cm__btn-group + .cm__btn-group {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main .cm--bar.cm--flip .cm__btns {
  flex-direction: row-reverse;
}

#cc-main .cm--bar.cm--flip .cm__btn:last-child {
  grid-column: 1;
}

#cc-main .cm--bar.cm--inline .cm__body, #cc-main .cm--bar.cm--inline .cm__links {
  max-width: 74em;
}

#cc-main .cm--bar.cm--inline .cm__body {
  flex-direction: row;
  padding: 0;
}

#cc-main .cm--bar.cm--inline .cm__btns {
  flex-direction: column;
  justify-content: center;
  max-width: 23em;
}

#cc-main .cm--bar.cm--inline.cm--flip .cm__btns {
  flex-direction: column-reverse;
}

#cc-main .cc--anim .cm, #cc-main .cc--anim.cm-wrapper:before {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease;
}

#cc-main .cc--anim .cm__btn, #cc-main .cc--anim .cm__close {
  transition: background-color .15s, border-color .15s, color .15s;
}

.disable--interaction #cc-main .cm-wrapper:before {
  background: var(--cc-overlay-bg);
  content: "";
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  position: fixed;
  inset: 0;
}

.show--consent #cc-main .cc--anim .cm {
  opacity: 1;
  transform: translateY(0);
  visibility: visible !important;
}

.show--consent #cc-main .cc--anim .cm--middle {
  transform: translateY(-50%);
}

.show--consent #cc-main .cc--anim .cm--bar {
  transform: translateY(0);
}

.show--consent #cc-main .cc--anim.cm-wrapper:before {
  opacity: 1;
  visibility: visible;
}

#cc-main.cc--rtl .cm__btn--close {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: var(--cc-btn-border-radius);
  left: 0;
  right: unset;
}

#cc-main.cc--rtl .cm__btn--close + .cm__texts .cm__title {
  padding-right: 1.3rem;
  padding-left: 3rem !important;
}

#cc-main.cc--rtl .cm--inline .cm__btn + .cm__btn {
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main.cc--rtl .cm--inline.cm--flip .cm__btn + .cm__btn {
  margin-left: .375rem;
  margin-right: 0;
}

#cc-main.cc--rtl .cm:not(.cm--inline).cm--bar .cm__btn + .cm__btn, #cc-main.cc--rtl .cm:not(.cm--inline).cm--bar .cm__btn-group + .cm__btn-group, #cc-main.cc--rtl .cm:not(.cm--inline).cm--wide .cm__btn + .cm__btn, #cc-main.cc--rtl .cm:not(.cm--inline).cm--wide .cm__btn-group + .cm__btn-group {
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main.cc--rtl .cm:not(.cm--inline).cm--bar.cm--flip .cm__btn + .cm__btn, #cc-main.cc--rtl .cm:not(.cm--inline).cm--wide.cm--flip .cm__btn + .cm__btn {
  margin-left: .375rem;
  margin-right: 0;
}

#cc-main.cc--rtl .cm__link-group > * + * {
  margin-left: 0;
  margin-right: 1.3rem;
}

@media screen and (max-width: 640px) {
  #cc-main {
    --cc-modal-margin: .5em;
  }

  #cc-main .cm {
    width: auto !important;
    max-width: none !important;
  }

  #cc-main .cm__body {
    flex-direction: column !important;
    padding: 0 !important;
  }

  #cc-main .cm__btns, #cc-main .cm__desc, #cc-main .cm__links, #cc-main .cm__title {
    padding-left: 1.1rem !important;
    padding-right: 1.1rem !important;
  }

  #cc-main .cm__btns {
    border-left: none !important;
    border-top: 1px solid var(--cc-separator-border-color) !important;
    flex-direction: column !important;
    min-width: auto !important;
    max-width: none !important;
  }

  #cc-main .cm__btn + .cm__btn, #cc-main .cm__btn-group + .cm__btn-group {
    margin: .375rem 0 0 !important;
  }

  #cc-main .cm--flip .cm__btn + .cm__btn, #cc-main .cm--flip .cm__btn-group + .cm__btn-group {
    margin-top: 0 !important;
    margin-bottom: .375rem !important;
  }

  #cc-main .cm__btn-group {
    flex-direction: column !important;
    min-width: auto !important;
    display: flex !important;
  }

  #cc-main .cm__btn {
    flex: auto !important;
  }

  #cc-main .cm__link-group {
    justify-content: center !important;
  }

  #cc-main .cm--flip .cm__btn-group, #cc-main .cm--flip .cm__btns {
    flex-direction: column-reverse !important;
  }
}

#cc-main .pm-wrapper {
  z-index: 2;
  position: relative;
}

#cc-main .pm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  opacity: 0;
  visibility: hidden;
  width: unset;
  z-index: 1;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: fixed;
  overflow: hidden;
  box-shadow: 0 .625em 1.875em #0000024d;
}

#cc-main svg {
  fill: none;
  width: 100%;
}

#cc-main .pm__body, #cc-main .pm__footer, #cc-main .pm__header {
  padding: 1em 1.4em;
}

#cc-main .pm__header {
  border-bottom: 1px solid var(--cc-separator-border-color);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#cc-main .pm__title {
  flex: 1;
  align-items: center;
  margin-right: 2em;
  font-weight: 600;
  display: flex;
}

#cc-main .pm__close-btn {
  background: var(--cc-btn-secondary-bg);
  border: 1px solid var(--cc-btn-secondary-border-color);
  border-radius: var(--cc-btn-border-radius);
  width: 40px;
  height: 40px;
  transition: all .15s;
  position: relative;
}

#cc-main .pm__close-btn span {
  width: 100%;
  height: 100%;
  display: flex;
}

#cc-main .pm__close-btn svg {
  stroke: var(--cc-btn-secondary-color);
  transition: stroke .15s;
  transform: scale(.5);
}

#cc-main .pm__close-btn:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
}

#cc-main .pm__close-btn:hover svg {
  stroke: var(--cc-btn-secondary-hover-color);
}

#cc-main .pm__body {
  overflow-y: auto;
  overflow-y: overlay;
  flex: 1;
}

#cc-main .pm__section, #cc-main .pm__section--toggle {
  border-radius: var(--cc-btn-border-radius);
  flex-direction: column;
  margin-bottom: .5em;
  display: flex;
}

#cc-main .pm__section--toggle .pm__section-desc-wrapper {
  border: 1px solid var(--cc-cookie-category-block-border);
  border-radius: var(--cc-btn-border-radius);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  overflow: hidden;
  margin-top: 0 !important;
}

#cc-main .pm__section {
  border: 1px solid var(--cc-separator-border-color);
  padding: 1em;
  transition: background-color .25s, border-color .25s;
}

#cc-main .pm__section:first-child {
  border: none;
  margin-top: 0;
  margin-bottom: 2em;
  padding: 0;
  transition: none;
}

#cc-main .pm__section:not(:first-child):hover {
  background: var(--cc-cookie-category-block-bg);
  border-color: var(--cc-cookie-category-block-border);
}

#cc-main .pm__section-toggles + .pm__section {
  margin-top: 2em;
}

#cc-main .pm__section--toggle {
  background: var(--cc-cookie-category-block-bg);
  border-top: none;
  margin-bottom: .375rem;
}

#cc-main .pm__section--toggle .pm__section-title {
  background: var(--cc-cookie-category-block-bg);
  border: 1px solid var(--cc-cookie-category-block-border);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 58px;
  padding: 1.1em 5.4em 1.1em 1.2em;
  transition: background-color .25s, border-color .25s;
  display: flex;
  position: relative;
}

#cc-main .pm__section--toggle .pm__section-title:hover {
  background: var(--cc-cookie-category-block-hover-bg);
  border-color: var(--cc-cookie-category-block-hover-border);
}

#cc-main .pm__section--toggle .pm__section-desc {
  margin-top: 0;
  padding: 1em;
}

#cc-main .pm__section--toggle.is-expanded {
  --cc-cookie-category-block-bg: var(--cc-cookie-category-expanded-block-bg);
  --cc-cookie-category-block-border: var(--cc-cookie-category-expanded-block-hover-bg);
}

#cc-main .pm__section--toggle.is-expanded .pm__section-title {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#cc-main .pm__section--toggle.is-expanded .pm__section-arrow svg {
  transform: scale(.5)rotate(180deg);
}

#cc-main .pm__section--toggle.is-expanded .pm__section-desc-wrapper {
  display: flex;
}

#cc-main .pm__section--expandable .pm__section-title {
  cursor: pointer;
  padding-left: 3.4em;
}

#cc-main .pm__section--expandable .pm__section-arrow {
  background: var(--cc-toggle-readonly-bg);
  pointer-events: none;
  border-radius: 100%;
  justify-content: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  left: 18px;
}

#cc-main .pm__section--expandable .pm__section-arrow svg {
  stroke: var(--cc-btn-secondary-color);
  transform: scale(.5);
}

#cc-main .pm__section-title-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

#cc-main .pm__section-title-wrapper + .pm__section-desc-wrapper {
  margin-top: .85em;
}

#cc-main .pm__section-title {
  border-radius: var(--cc-btn-border-radius);
  font-size: .95em;
  font-weight: 600;
}

#cc-main .pm__badge {
  background: var(--cc-btn-secondary-bg);
  color: var(--cc-secondary-color);
  text-align: center;
  white-space: nowrap;
  border-radius: 5em;
  flex: none;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 23px;
  height: 23px;
  margin-left: 1em;
  padding: 0 .6em 1px;
  font-size: .8em;
  font-weight: 600;
  display: flex;
  position: relative;
  overflow: hidden;
}

#cc-main .pm__service-counter {
  background: var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-color);
  width: 23px;
  padding: 0;
}

#cc-main .pm__service-counter[data-counterlabel] {
  width: auto;
  padding: 0 .6em 1px;
}

#cc-main .section__toggle, #cc-main .section__toggle-wrapper, #cc-main .toggle__icon, #cc-main .toggle__label {
  border-radius: var(--cc-pm-toggle-border-radius);
  width: 50px;
  height: 23px;
  transform: translateZ(0);
}

#cc-main .section__toggle-wrapper {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 18px;
}

#cc-main .toggle-service {
  width: 42px;
  height: 19px;
  position: relative;
  right: 0;
}

#cc-main .toggle-service .section__toggle, #cc-main .toggle-service .toggle__icon, #cc-main .toggle-service .toggle__label {
  width: 42px;
  height: 19px;
}

#cc-main .toggle-service .toggle__icon {
  position: relative;
}

#cc-main .toggle-service .toggle__icon-circle {
  width: 19px;
  height: 19px;
}

#cc-main .toggle-service .section__toggle:checked ~ .toggle__icon .toggle__icon-circle {
  transform: translateX(23px);
}

#cc-main .pm__section--toggle:nth-child(2) .section__toggle-wrapper:after {
  display: none !important;
}

#cc-main .section__toggle {
  cursor: pointer;
  border: 0;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#cc-main .section__toggle:disabled {
  cursor: not-allowed;
}

#cc-main .toggle__icon {
  background: var(--cc-toggle-off-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-off-bg);
  pointer-events: none;
  flex-direction: row;
  transition: all .25s;
  display: flex;
  position: absolute;
}

#cc-main .toggle__icon-circle {
  background: var(--cc-toggle-off-knob-bg);
  border-radius: var(--cc-pm-toggle-border-radius);
  border: none;
  width: 23px;
  height: 23px;
  transition: transform .25s, background-color .25s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 2px #1820035c;
}

#cc-main .toggle__icon-off, #cc-main .toggle__icon-on {
  width: 100%;
  height: 100%;
  transition: opacity .15s;
  position: absolute;
}

#cc-main .toggle__icon-on {
  opacity: 0;
  transform: rotate(45deg);
}

#cc-main .toggle__icon-on svg {
  stroke: var(--cc-toggle-on-bg);
  transform: scale(.55)rotate(-45deg);
}

#cc-main .toggle__icon-off {
  opacity: 1;
}

#cc-main .toggle__icon-off svg {
  stroke: var(--cc-toggle-off-bg);
  transform: scale(.55);
}

#cc-main .section__toggle:checked ~ .toggle__icon {
  background: var(--cc-toggle-on-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-on-bg);
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-circle {
  background-color: var(--cc-toggle-on-knob-bg);
  transform: translateX(27px);
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-on {
  opacity: 1;
}

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-off {
  opacity: 0;
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon {
  background: var(--cc-toggle-readonly-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-readonly-bg);
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon .toggle__icon-circle {
  background: var(--cc-toggle-readonly-knob-bg);
  box-shadow: none;
}

#cc-main .section__toggle:checked:disabled ~ .toggle__icon svg {
  stroke: var(--cc-toggle-readonly-knob-icon-color);
}

#cc-main .toggle__label {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#cc-main .pm__section-desc-wrapper {
  color: var(--cc-secondary-color);
  flex-direction: column;
  font-size: .9em;
  display: flex;
}

#cc-main .pm__section-desc-wrapper > :not(:last-child) {
  border-bottom: 1px solid var(--cc-cookie-category-block-border);
}

#cc-main .pm__section-services {
  flex-direction: column;
  display: flex;
}

#cc-main .pm__service {
  justify-content: space-between;
  align-items: center;
  padding: .4em 1.2em;
  transition: background-color .15s;
  display: flex;
  position: relative;
}

#cc-main .pm__service:hover {
  background-color: var(--cc-cookie-category-block-hover-bg);
}

#cc-main .pm__service-header {
  align-items: center;
  width: 100%;
  margin-right: 1em;
  display: flex;
}

#cc-main .pm__service-icon {
  border: 2px solid;
  border-radius: 100%;
  min-width: 8px;
  height: 8px;
  margin-top: 1px;
  margin-left: 6px;
  margin-right: 20px;
}

#cc-main .pm__service-title {
  word-break: break-word;
  width: 100%;
  font-size: .95em;
}

#cc-main .pm__section-desc {
  line-height: 1.5em;
}

#cc-main .pm__section-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: .9em;
  overflow: hidden;
}

#cc-main .pm__table-caption {
  text-align: left;
}

#cc-main .pm__table-caption, #cc-main .pm__table-head > tr {
  border-bottom: 1px dashed var(--cc-separator-border-color);
  color: var(--cc-primary-color);
  font-weight: 600;
}

#cc-main .pm__table-tr {
  transition: background-color .15s;
}

#cc-main .pm__table-tr:hover {
  background: var(--cc-cookie-category-block-hover-bg);
}

#cc-main .pm__table-caption, #cc-main .pm__table-td, #cc-main .pm__table-th {
  vertical-align: top;
  padding: .625em .625em .625em 1.2em;
}

#cc-main .pm__footer {
  border-top: 1px solid var(--cc-separator-border-color);
  justify-content: space-between;
  display: flex;
}

#cc-main .pm__btn-group {
  display: flex;
}

#cc-main .pm__btn + .pm__btn, #cc-main .pm__btn-group + .pm__btn-group {
  margin-left: .375rem;
}

#cc-main .pm--flip .pm__btn + .pm__btn, #cc-main .pm--flip .pm__btn-group + .pm__btn-group {
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main .pm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  text-align: center;
  flex: auto;
  min-width: 110px;
  min-height: 42px;
  padding: .5em 1.5em;
  font-size: .82em;
  font-weight: 600;
  transition: background-color .15s, border-color .15s, color .15s;
}

#cc-main .pm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color);
}

#cc-main .pm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color);
}

#cc-main .pm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color);
}

#cc-main .pm--box {
  left: var(--cc-modal-margin);
  right: var(--cc-modal-margin);
  max-width: 43em;
  height: calc(100% - 2em);
  max-height: 37.5em;
  margin: 0 auto;
  top: 50%;
  transform: translateY(calc(1.6em - 50%));
}

#cc-main .pm--box.pm--flip .pm__btn-group, #cc-main .pm--box.pm--flip .pm__footer {
  flex-direction: row-reverse;
}

#cc-main .pm--bar {
  opacity: 1;
  --cc-modal-transition-duration: .35s;
  border-radius: 0;
  width: 100%;
  max-width: 29em;
  height: 100%;
  max-height: none;
  margin: 0;
  top: 0;
  bottom: 0;
}

#cc-main .pm--bar .pm__section-table, #cc-main .pm--bar .pm__table-body, #cc-main .pm--bar .pm__table-td, #cc-main .pm--bar .pm__table-th, #cc-main .pm--bar .pm__table-tr {
  display: block;
}

#cc-main .pm--bar .pm__table-head {
  display: none;
}

#cc-main .pm--bar .pm__table-caption {
  display: block;
}

#cc-main .pm--bar .pm__table-tr:not(:last-child) {
  border-bottom: 1px solid var(--cc-separator-border-color);
}

#cc-main .pm--bar .pm__table-td {
  justify-content: space-between;
  display: flex;
}

#cc-main .pm--bar .pm__table-td:before {
  color: var(--cc-primary-color);
  content: attr(data-column);
  text-overflow: ellipsis;
  flex: 1;
  min-width: 100px;
  padding-right: 2em;
  font-weight: 600;
  overflow: hidden;
}

#cc-main .pm--bar .pm__table-td > div {
  flex: 3;
}

#cc-main .pm--bar:not(.pm--wide) .pm__body, #cc-main .pm--bar:not(.pm--wide) .pm__footer, #cc-main .pm--bar:not(.pm--wide) .pm__header {
  padding: 1em 1.3em;
}

#cc-main .pm--bar:not(.pm--wide) .pm__btn-group, #cc-main .pm--bar:not(.pm--wide) .pm__footer {
  flex-direction: column;
}

#cc-main .pm--bar:not(.pm--wide) .pm__btn + .pm__btn, #cc-main .pm--bar:not(.pm--wide) .pm__btn-group + .pm__btn-group {
  margin: .375rem 0 0;
}

#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn-group, #cc-main .pm--bar:not(.pm--wide).pm--flip .pm__footer {
  flex-direction: column-reverse;
}

#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn + .pm__btn, #cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn-group + .pm__btn-group {
  margin-top: 0;
  margin-bottom: .375rem;
}

#cc-main .pm--bar:not(.pm--wide) .pm__badge {
  display: none;
}

#cc-main .pm--bar.pm--left {
  left: 0;
  transform: translateX(-100%);
}

#cc-main .pm--bar.pm--right {
  right: 0;
  transform: translateX(100%);
}

#cc-main .pm--bar.pm--wide {
  max-width: 35em;
}

#cc-main .pm--bar.pm--wide .pm__body, #cc-main .pm--bar.pm--wide .pm__footer, #cc-main .pm--bar.pm--wide .pm__header {
  padding: 1em 1.4em;
}

#cc-main .pm--bar.pm--wide.pm--flip .pm__btn-group, #cc-main .pm--bar.pm--wide.pm--flip .pm__footer {
  flex-direction: row-reverse;
}

#cc-main .pm-overlay {
  background: var(--cc-overlay-bg);
  content: "";
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  position: fixed;
  inset: 0;
}

#cc-main .cc--anim .pm, #cc-main .cc--anim .pm-overlay {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease;
}

.show--preferences #cc-main .cc--anim .pm {
  opacity: 1;
  visibility: visible !important;
}

.show--preferences #cc-main .cc--anim .pm--box {
  transform: translateY(-50%);
}

.show--preferences #cc-main .cc--anim .pm--bar {
  transform: translateX(0);
}

.show--preferences #cc-main .cc--anim .pm-overlay {
  opacity: 1;
  visibility: visible;
}

#cc-main.cc--rtl .pm__service-header {
  margin-left: 1em;
  margin-right: 0;
}

#cc-main.cc--rtl .pm__section-arrow {
  left: unset;
  right: 18px;
}

#cc-main.cc--rtl .section__toggle-wrapper {
  left: 18px;
  right: unset;
  transform-origin: 0;
}

#cc-main.cc--rtl .toggle-service {
  left: 0;
}

#cc-main.cc--rtl .pm__service-icon {
  margin-left: 20px;
  margin-right: 5px;
}

#cc-main.cc--rtl .pm__section--toggle .pm__section-title {
  padding-left: 5.4em;
  padding-right: 1.2em;
}

#cc-main.cc--rtl .pm__section--expandable .pm__section-title {
  padding-right: 3.4em;
}

#cc-main.cc--rtl .pm__badge {
  margin-left: unset;
  margin-right: 1em;
}

#cc-main.cc--rtl .toggle__icon-circle {
  transform: translateX(27px);
}

#cc-main.cc--rtl .toggle-service .toggle__icon-circle {
  transform: translateX(23px);
}

#cc-main.cc--rtl .section__toggle:checked ~ .toggle__icon .toggle__icon-circle {
  transform: translateX(0);
}

#cc-main.cc--rtl .pm__table-td, #cc-main.cc--rtl .pm__table-th {
  padding-left: unset;
  text-align: right;
  padding-right: 1.2em;
}

#cc-main.cc--rtl .pm__table-td {
  padding-left: unset;
  padding-right: 1.2em;
}

#cc-main.cc--rtl .pm__table-td:before {
  padding-left: 2em;
  padding-right: unset;
}

#cc-main.cc--rtl .pm__btn + .pm__btn, #cc-main.cc--rtl .pm__btn-group + .pm__btn-group {
  margin-left: 0;
  margin-right: .375rem;
}

#cc-main.cc--rtl .pm--flip .pm__btn + .pm__btn, #cc-main.cc--rtl .pm--flip .pm__btn-group + .pm__btn-group {
  margin-left: .375rem;
  margin-right: 0;
}

#cc-main.cc--rtl .pm--flip.pm--bar:not(.pm--wide) .pm__btn + .pm__btn, #cc-main.cc--rtl .pm--flip.pm--bar:not(.pm--wide) .pm__btn-group + .pm__btn-group {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  #cc-main .pm {
    border-radius: 0;
    height: auto;
    max-height: 100%;
    inset: 0;
    transform: translateY(1.6em);
    max-width: none !important;
  }

  #cc-main .pm__body, #cc-main .pm__footer, #cc-main .pm__header {
    padding: .9em !important;
  }

  #cc-main .pm__badge {
    display: none;
  }

  #cc-main .pm__section-table, #cc-main .pm__table-body, #cc-main .pm__table-caption, #cc-main .pm__table-td, #cc-main .pm__table-th, #cc-main .pm__table-tr {
    display: block;
  }

  #cc-main .pm__table-head {
    display: none;
  }

  #cc-main .pm__table-tr:not(:last-child) {
    border-bottom: 1px solid var(--cc-separator-border-color);
  }

  #cc-main .pm__table-td {
    justify-content: space-between;
    display: flex;
  }

  #cc-main .pm__table-td:before {
    color: var(--cc-primary-color);
    content: attr(data-column);
    text-overflow: ellipsis;
    flex: 1;
    min-width: 100px;
    padding-right: 2em;
    font-weight: 600;
    overflow: hidden;
  }

  #cc-main .pm__table-td > div {
    flex: 3;
  }

  #cc-main .pm__btn-group, #cc-main .pm__footer {
    flex-direction: column !important;
  }

  #cc-main .pm__btn-group {
    display: flex !important;
  }

  #cc-main .pm__btn + .pm__btn, #cc-main .pm__btn-group + .pm__btn-group {
    margin: .375rem 0 0 !important;
  }

  #cc-main .pm--flip .pm__btn + .pm__btn, #cc-main .pm--flip .pm__btn-group + .pm__btn-group {
    margin-top: 0 !important;
    margin-bottom: .375rem !important;
  }

  #cc-main .pm--flip .pm__btn-group, #cc-main .pm--flip .pm__footer {
    flex-direction: column-reverse !important;
  }

  .show--preferences #cc-main .cc--anim .pm {
    transform: translateY(0) !important;
  }
}

#cc-main {
  color-scheme: light;
  --cc-bg: #f9faff;
  --cc-primary-color: #112954;
  --cc-secondary-color: #112954;
  --cc-btn-primary-bg: #3859d0;
  --cc-btn-primary-color: var(--cc-bg);
  --cc-btn-primary-hover-bg: #213657;
  --cc-btn-primary-hover-color: #fff;
  --cc-btn-secondary-bg: #dfe7f9;
  --cc-btn-secondary-color: var(--cc-secondary-color);
  --cc-btn-secondary-hover-bg: #c6d1ea;
  --cc-btn-secondary-hover-color: #000;
  --cc-cookie-category-block-bg: #ebeff9;
  --cc-cookie-category-block-border: #ebeff9;
  --cc-cookie-category-block-hover-bg: #dbe5f9;
  --cc-cookie-category-block-hover-border: #dbe5f9;
  --cc-cookie-category-expanded-block-hover-bg: #ebeff9;
  --cc-cookie-category-expanded-block-bg: #ebeff9;
  --cc-toggle-readonly-bg: #cbd8f1;
  --cc-toggle-on-knob-bg: var(--cc-bg);
  --cc-toggle-off-bg: #8fa8d6;
  --cc-toggle-readonly-knob-bg: var(--cc-bg);
  --cc-separator-border-color: #f1f3f5;
  --cc-footer-border-color: #f1f3f5;
  --cc-footer-bg: var(--cc-bg);
  --cc-overlay-bg: #dbe8ffd9 !important;
}

/*# sourceMappingURL=wccc.css.map */
